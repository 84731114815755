<!-- <div class="row">
    <img style="width:6%" src="assets/img/backbtn.png" />
</div>
<app-basic-details [screentype] = "2" [memberMealDetails] = "" [selectedTab]=""></app-basic-details> -->

<div class="title mt-4">{{details.name}}</div>

<div class="content mt-4">
  {{details.description}}
</div>


<div class="col-md-12 mt-4 flex" style="padding: 0;"></div>
    <div class="col-md-6">
    <mat-list role="list" style="display: flex;">
        <mat-list-item class="col-md-3 tab" [ngClass]="{'active': isType === 1}"  (click)="tabShow(1)" role="listitem" >
            <div >
                <div class="tabContent" >Ingredients</div>
            </div>
        </mat-list-item>
        <mat-list-item class="col-md-4 tab" [ngClass]="{'active': isType === 2}" role="listitem" (click)="tabShow(2)">
            <div >
                <div class="tabContent">Cooking Methods</div>
            </div>
        </mat-list-item>
      
        
      </mat-list>
    </div>

    <div class="row contentList mt-5">{{details.name}}</div>
    <div class="row mt-2" *ngIf="isIngredient">

        <mat-list>
            <mat-list-item *ngFor="let ingredients of details.ingredientsBreakdown">
              <span class="contentList" matListItemTitle>{{ingredients.quantity}} {{ingredients.unit}} {{ingredients.name}}</span>
              
            </mat-list-item>
            
          </mat-list>
    </div>

    <div class="row mt-2"  *ngIf="isCooking">

      <mat-list>
          <mat-list-item *ngFor="let cook of details.cookingInstructions">
            <span class="contentList mt-3" matListItemTitle>
              Step {{cook.step}} : 

              {{cook.description}} 
            
            </span>

            <!-- <span class="contentList1 mt-4" matListItemTitle>{{cook.description}} </span> -->
            
          </mat-list-item>
          
        </mat-list>
  </div>