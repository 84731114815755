import { Component, ViewChild } from '@angular/core';
import {MatListModule} from '@angular/material/list';
import { MealNotificationComponent } from './meal-notification/meal-notification.component';
import { MemberMealDetailsComponent } from './member-meal-details/member-meal-details.component';
import { SharedServiceService } from '../services/shared-service.service';
import { MealPlanService } from './meal-plan.service';
import { NgForOf } from '@angular/common';
import { CommonModule, formatDate, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';

@Component({
  selector: 'app-meal-plan',
  standalone: true,
  imports: [MatListModule,MealNotificationComponent,MemberMealDetailsComponent,NgForOf,NgIf,CommonModule,FormsModule,MatPaginator,MatPaginatorModule],
  templateUrl: './meal-plan.component.html',
  styleUrl: './meal-plan.component.css'
})
export class MealPlanComponent {

  constructor(private service:MealPlanService,private SharedService:SharedServiceService) {
    let user= this.SharedService.getLoggedInUser();
    this.userLoggedIn = user.id
  }
  @ViewChild('paginator') paginator: MatPaginator;
  memberList:any=[];
  countRes:any=[];
  tab1Css:any = 'active tab';
  tab2Css:any = 'tab';
  tab3Css:any = 'tab';
  selectedTab:any = 'tab1';
  filter:any='all';
  timeout: any = null;
  name:any = '';
  userLoggedIn:any = '';
  currentDate = formatDate(Date.now(), 'yyyy-MM-dd','en-US');
  resp:any = [];
  previousPageSize:any = 10;
  pageIndex:any = 1;
  ngOnInit() :void{
   this.callApi('all','',this.pageIndex,10);
   this.service.getMemberMealCount({'userLoggedIn':this.userLoggedIn,'currentDate':this.currentDate}).subscribe(response => { 
    this.countRes = response;
  }); 
}
changeTab(type:string){
  this.paginator.pageIndex = 0;
  this.pageIndex = 1;
  this.name = '';
  if(type == 'tab1'){
    this.selectedTab = 'tab1';
    this.filter = 'all';
    this.tab1Css = 'active tab';
    this.tab2Css = 'tab';
    this.tab3Css = 'tab';
    this.callApi(this.filter,'',this.pageIndex,this.paginator.pageSize);
  }else if(type == 'tab2'){
    this.filter = 'unassigned';
    this.selectedTab = 'tab2';
    this.tab1Css = 'tab';
    this.tab2Css = 'active tab';
    this.tab3Css = 'tab';
    this.callApi(this.filter,'',this.pageIndex,this.paginator.pageSize);
  }else{
    this.filter = 'expiring';
    this.selectedTab = 'tab3';
    this.tab1Css = 'tab';
    this.tab2Css = 'tab';
    this.tab3Css = 'active tab';
    this.callApi(this.filter,'',this.pageIndex,this.paginator.pageSize);
}
}

searchByMember(event: any){
  this.paginator.pageIndex = 0;
  this.pageIndex = 1;
  clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.executeListing(event.target.value);
      }
    }, 500);
}
executeListing(searchValue:any){
this.name = searchValue;
this.callApi(this.filter,searchValue,this.pageIndex,this.paginator.pageSize);
}

pageChangeEvent(page:any){
  if(page.pageSize != this.previousPageSize){
   this.paginator.pageIndex = 0;
  }
  this.previousPageSize = page.pageSize;
  this.pageIndex = this.paginator.pageIndex+1;
  this.callApi(this.filter,this.name,this.pageIndex ,this.paginator.pageSize);
 }

callApi(filterType:any,name:any,pageNo:number,pageSize:number){ {
   this.memberList = [];
    var obj = {
      'userLoggedIn':this.userLoggedIn,
      'currentDate':this.currentDate,
      'filterType':filterType,
      'name':name,
    };
    this.service.getMemberMealInfo(obj,pageNo,pageSize).subscribe(response => { 
    this.resp = response;
    let list = this.resp['result'];
    for (let i = 0; i < list.length; i += 3) {
         this.memberList.push(list.slice(i, i + 3));
     }
  }); 
}
}
}
