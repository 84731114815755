<div class="row">
    <img style="width:6%" (click)="backBtn()" src="assets/img/backbtn.png" />
</div>
<app-basic-details [screentype] = "2"></app-basic-details>

<div class="row mt-5 ml-2">
    <div class="title">All Meal Plans</div>
</div>
<div *ngIf="isback == 1">
<div class="row mt-4 flex" style="padding: 0;">
    <div class="col">
    <mat-list role="list" class="flex">
        <mat-list-item class="col-md-3"  role="listitem" >
            <div class="active tab">
                <div class="tabContent" (click)="filterPlans(1)">Ongoing Meal Plan</div>
            </div>
        </mat-list-item>
        <mat-list-item class="col-md-4"  role="listitem">
            <div class=" tab">
                <div class="tabContent" (click)="filterPlans(2)">Completed Meal Plan</div>
            </div>
        </mat-list-item>
      </mat-list>
    </div>
</div>
<div *ngIf="mealResult.length > 0">
<div class="row mt-5" *ngFor="let meal of mealResult;let i=index">
    
    <div class="col-md-12 mt-4">
        <div class="subTitle capitalize">{{meal.day}}</div>
    </div>
    <div class="mt-4" *ngIf="meal.meals <1">
       <h5> No Meal For {{meal.day}}</h5>
    </div>
    <div class="col-6 mt-3" *ngFor="let recipe of meal.meals">
        
        <div class="meal" *ngIf="recipe.isFasting == false">
            <div class="row flex">
                <div class="col mealType">
                    {{recipe.recipes[0].recipeType}}
                </div>
                <div class="col time" style="text-align: right;">
                    {{getDate(recipe.mealtime)}}
                </div>
            </div>
            <div class="row flex mt-4">
                <!-- <div class="col-2">
                    <img src="/assets/img/mealpic.png"/>
                </div> -->
                <div class="col">
                    <div class="col-md-8 ml-4">
                        <div class="mealName">{{recipe.recipes[0].recipeName}},  {{getType(recipe.recipes[0].measure,recipe.recipes[0].measurementUnitValue,recipe.recipes[0].unit)}}
                   
                        </div>
                    </div>
                    <div class="row mt-5 ">
                        <div class="col-3">
                            <div class="col-md-12 flex">
                                <img class="imgClass" src="/assets/img/calories.png"/> &nbsp;
                                <div class="nutriTitle mt-2">Calories</div>
                            </div>
                            <div class="col-md-12 mt-2 val ml-4">
                                {{recipe.recipes[0].cals}}
                            </div>
                            <div class="col-md-12 mt-2 unit ml-4">
                                cal
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="col-md-12 flex">
                                <img class="imgClass" src="/assets/img/protien.png"/> &nbsp;
                                <div class="nutriTitle mt-2">Protien</div>
                            </div>
                            <div class="col-md-12 mt-2 val ml-4">
                                {{recipe.recipes[0].protien}}
                            </div>
                            <div class="col-md-12 mt-2 unit ml-4">
                                grams
                            </div>
                        </div>
        
                        <div class="col-3">
                            <div class="col-md-12 flex">
                                <img class="imgClass" src="/assets/img/carbs.png"/> &nbsp;
                                <div class="nutriTitle mt-2">Carbs</div>
                            </div>
                            <div class="col-md-12 mt-2 val ml-4">
                                {{recipe.recipes[0].carbs}}
                            </div>
                            <div class="col-md-12 mt-2 unit ml-4">
                                grams
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="col-md-12 flex">
                                <img class="fat" src="/assets/img/fat1.png"/> &nbsp;
                                <div class="nutriTitle mt-2">Fat</div>
                            </div>
                            <div class="col-md-12 mt-2 val ml-3">
                                {{recipe.recipes[0].fat}}
                            </div>
                            <div class="col-md-12 mt-2 unit ml-3">
                                grams
                            </div>
                        </div>
                        
                        </div>
        
                        
                </div>
            </div>
            <div class="col-md-12 textAlign mt-4 flex">
                 <button class="btn btnClass"   (click)="viewMealPlan(recipe.recipes[0].recipeId)" >View More Details</button>
                </div>
           
        </div>

        <div class="meal" *ngIf="recipe.isFasting == true">
            <div class="col-md-12 ">
                <div class="col mealType">
                   Fasting
                </div>
            </div>
                <div class="row flex mt-4">
                    <div class="col-2">
                        <img src="/assets/img/fastingIcon.png"/>
                    </div>
                    <div class="col-9 ml-1">
                        <div class="row ">
                            <div class="col-sm">
                                <div class="col-md-12 flex">
                                  
                                    <div class="nutriTitle mt-2">Start Time</div>
                                </div>
                                <div class="col-md-12 mt-2 val ml-4">
                                    {{recipe.fastings[0].fromTime}}
                                </div>
                                
                            </div>
                            <div class="col-sm">
                                <div class="col-md-12 flex">
                                    
                                    <div class="nutriTitle mt-2">End Time</div>
                                </div>
                                <div class="col-md-12 mt-2 val ml-4">
                                    {{recipe.fastings[0].toTime}}
                                </div>
                               
                            </div>
            
                            <div class="col-sm">
                                <div class="col-md-12 flex">
                                   
                                    <div class="nutriTitle mt-2">Duration</div>
                                </div>
                                <div class="col-md-12 mt-2 val ml-4">
                                    {{recipe.fastings[0].duration}}h
                                </div>
                             
                            </div>
                         
                            
                            </div>
                    </div>
                </div>
           
        </div>
    </div>
   

</div>
</div>

<div *ngIf="mealResult.length < 0">
    No Record Found.
</div>

</div>

<div *ngIf="isback == 2">
    <div class="title mt-4">{{viewDetials.recipeName}}</div>

    <div class="content mt-4">
      {{viewDetials.shortDescription}}
    </div>
    
    
    <div class="col-md-12 mt-4 flex" style="padding: 0;"></div>
        <div class="col-md-6">
        <mat-list role="list" style="display: flex;">
            <mat-list-item class="col-md-3 tab" [ngClass]="{'active': isType === 1}"  (click)="tabShow(1)" role="listitem" >
                <div >
                    <div class="tabContent" >Ingredients</div>
                </div>
            </mat-list-item>
            <mat-list-item class="col-md-4 tab" [ngClass]="{'active': isType === 2}" role="listitem" (click)="tabShow(2)">
                <div >
                    <div class="tabContent">Cooking Methods</div>
                </div>
            </mat-list-item>
          
            
          </mat-list>
        </div>
    
        <div class="row contentList mt-5">{{viewDetials.recipeName}}</div>
        <div class="row mt-2" *ngIf="isIngredient">
    
            <mat-list>
                <mat-list-item *ngFor="let ingredients of viewDetials.ingredients">
                  <span class="contentList" matListItemTitle>{{ingredients.qty}} {{ingredients.measureUnit}} {{ingredients.ingredientName}}</span>
                  
                </mat-list-item>
                
              </mat-list>
        </div>
    
        <div class="row mt-2"  *ngIf="isCooking">
    
          <mat-list>
              <mat-list-item *ngFor="let cook of viewDetials.preparationSteps">
                <span class="contentList mt-3" matListItemTitle>
                  Step {{cook.step}} : 
    
                  {{cook.description}} 
                
                </span>
    
                <!-- <span class="contentList1 mt-4" matListItemTitle>{{cook.description}} </span> -->
                
              </mat-list-item>
              
            </mat-list>
      </div>
</div>