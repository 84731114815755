<div class="row mt-5">
    <div class="title">Cuisine Preference</div>
</div>

<div class="row mt-4">
    <div class="col-sm">
        <div class="mealTitle">Default Cuisine</div>.
        <div class="row mt-3" *ngIf="this.allPrefer.cuisine != undefined">
            <div class="col-md-6 colDiv1 activeCol1 mt-2" *ngFor='let cusin of this.allPrefer.cuisine.defaultCuisines;let i=index'>
                <div class="curisieSubTitle">{{cusin}}</div>
                <div class="crossMain" >
                <div class="cross" (click)="removePreference(i,1)">X</div>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-2">
            <div class="cross1Main" (click)="addPreference(1)">
                <div class="cross2Sub">
                <div class="cross1">+</div>
                </div>
                </div>
        </div>
        <div class="flex mt-4" *ngIf="isAddValue == 1">
            <input type="text" class="form-control textField textContent"  [(ngModel)]="preferValue" [ngModelOptions]="{standalone: true}" placeholder="Enter Value"/> &nbsp;&nbsp; <button type="button" (click)="savePreference()" class="btn btnClass">Save</button> 
        </div>
       
    </div>

    <div class="col-sm ml-1">
        <div class="mealTitle">Other Liked Cuisine</div>
        <div class="row mt-3" *ngIf="this.allPrefer.cuisine != undefined">
            <div class="col-md-6 colDiv1 activeCol1 mt-4" *ngFor='let likesCuisin of allPrefer.cuisine.likedCuisines;let i=index'>
                <div class="curisieSubTitle">{{likesCuisin}}</div>
                <div class="crossMain">
                <div class="cross" (click)="removePreference(i,2)">X</div>
                </div>
            </div>

            
           
            
        </div>
        <div class="col-md-12 mt-2">
            <div class="cross1Main" (click)="addPreference(2)">
                <div class="cross2Sub">
                <div class="cross1">+</div>
                </div>
                </div>
        </div>

        <div class="flex mt-4" *ngIf="isAddValue == 2">
            <input type="text" class="form-control textField textContent"   [(ngModel)]="preferValue" [ngModelOptions]="{standalone: true}" placeholder="Enter Value"/> &nbsp;&nbsp; <button type="button" (click)="savePreference()" class="btn btnClass">Save</button> 
        </div>

    </div>
    <div class="col-sm">
        <div class="mealTitle">Disliked Cuisine</div>
        <div class="flex mt-3" *ngIf="this.allPrefer.cuisine != undefined">
            <div class="col-4 colDivRed mt-2" *ngFor='let dislike of allPrefer.cuisine.dislikedCuisines;let i=index;'>
                <div class="curisieSubTitleRed">{{dislike}}</div>
                <div class="crossMain">
                <div class="crossRed" (click)="removePreference(i,3)">X</div>
                </div>
            </div>

           
            <div class="col-4 mt-3">
                <div class="cross1Main" (click)="addPreference(3)">
                    <div class="cross2Sub">
                    <div class="cross1">+</div>
                    </div>
                    </div>
            </div>
           
        </div>
        <div class="flex mt-4" *ngIf="isAddValue == 3">
            <input type="text" class="form-control textField textContent"  [(ngModel)]="preferValue" [ngModelOptions]="{standalone: true}" placeholder="Enter Value"/> &nbsp;&nbsp; <button type="button" (click)="savePreference()" class="btn btnClass">Save</button> 
        </div>
       
    </div>

    <div class="col-md-12 mt-4">
        <div class="mealTitle">Food Restrictions</div>
        <div class="flex mt-3" *ngIf="this.allPrefer.cuisine != undefined">
            <div class="col-4 colDiv1 activeCol1" *ngFor='let food of allPrefer.cuisine.foodRestrictions;let i=index;'>
                <div class="curisieSubTitle">{{food}}</div>
                <div class="crossMain">
                <div class="cross" (click)="removePreference(i,4)">X</div>
                </div>
            </div>

           
            <div class="col-3">
                <div class="cross1Main" (click)="addPreference(4)">
                    <div class="cross2Sub">
                    <div class="cross1">+</div>
                    </div>
                    </div>
            </div>
            
        </div>

        <div class="flex mt-4" *ngIf="isAddValue == 4">
            <input type="text" class="form-control textField textContent"  [(ngModel)]="preferValue" [ngModelOptions]="{standalone: true}" placeholder="Enter Value"/> &nbsp;&nbsp; <button type="button" (click)="savePreference()" class="btn btnClass">Save</button> 
        </div>

       
    </div>
</div>

<hr class="mt-5">

<div class="row mt-5">
    <div class="title">Lifestyle</div>
</div>

<div class="row mt-5">
    <div class="mealTitle">Cooking Time</div>
</div>

<div class="row  flex">
    <div class="flex mt-3">
        <div *ngIf="selectedType[1] == 1">
            <div>Breakfast Preparation Time</div>
            <div>
                <input type="number" class="form-control textField1 textContent"   [(ngModel)]="allPrefer.lifeStyle.bPrepTime"  [ngModelOptions]="{standalone: true}" placeholder="Breakfast Prep Time"/>
            </div>
        </div>
       

         &nbsp;&nbsp; 
         <div *ngIf="selectedType[2] == 1">
            <div>Lunch Preparation Time</div>
            <div>
                <input type="number" [(ngModel)]="allPrefer.lifeStyle.lPrepTime"   [ngModelOptions]="{standalone: true}" placeholder="Lunch Prep Time" class="form-control textField1 textContent"/>
            </div>
        </div>
        
        &nbsp;&nbsp;
        <div *ngIf="selectedType[3] == 1">
            <div>Dinner Preparation Time</div>
            <div>
                <input type="number" [(ngModel)]="allPrefer.lifeStyle.dPrepTime"  [ngModelOptions]="{standalone: true}" class="form-control textField1 textContent" placeholder="Dinner Prep Time"/>
            </div>
        </div>
         &nbsp;&nbsp;
         <div *ngIf="selectedType[4] == 1">
            <div>Snack Preparation Time</div>
            <div>
                <input type="number" [(ngModel)]="allPrefer.lifeStyle.sPrepTime"  [ngModelOptions]="{standalone: true}" placeholder="Snack Prep Time" class="form-control textField1 textContent"/>
            </div>
        </div>
        
    </div>
</div>




<div class="row mt-5 flex">
    <div class="col-3">
        <div class="mealTitle">Kid Friendly</div>
    
        <div class="flex mt-4">
            <div class="col-4 colDiv1 " [ngClass]="{'activeCol1': isKidFriendly === 1}">
                <div class="curisieSubTitle">Yes</div>
                <div class="crossMain">
                <div class="cross" (click)="kidFriendly(1)"  *ngIf="isKidFriendly == 1">X</div>
                <div class="cross" (click)="kidFriendly(2)"  *ngIf="isKidFriendly == 2" >+</div>
                </div>
            </div>
            
        </div>
    </div>

    <div class="col-3">
        <div class="mealTitle">Meal Planning Friendly </div>
    
        <div class="flex mt-4">
            <div class="col-4 colDiv1"  [ngClass]="{'activeCol1': isPlanFriendly === 1}">
                <div class="curisieSubTitle" >Yes </div>
                <div class="crossMain">
                    <div class="cross" (click)="planFriendly(1)"  *ngIf="isPlanFriendly == 1">X</div>
                    <div class="cross" (click)="planFriendly(2)"  *ngIf="isPlanFriendly == 2" >+</div>
                </div>
            </div>
            <!-- <div class="col-4 ml-2 colDiv1" [ngClass]="{'activeCol1': allPrefer.lifeStyle.isMealPlanningFriendly === false}">
                <div class="curisieSubTitle">No</div>
                <div class="crossMain">
                <div class="cross">X</div>
                </div>
            </div> -->
        </div>
    </div>
    
    
</div>


<hr class="mt-5">

<div class="row mt-5">
    <div class="title">Allergies</div>
</div>

<div class="row mt-2">
    <div class="mealTitle">These are the food that are allergic to the member</div>
</div>

<div class="row mt-3">
    <div class="flex mt-4">
        <div class="col activeCol" *ngFor='let allegery of allPrefer.allergies;'>
            <div class="curisieSubTitle" *ngIf='allegery != ""'>{{allegery}}</div>           
        </div>

    </div>
</div>


<hr class="mt-5">

<div class="row mt-5">
    <div class="title">Ingredient Preference</div>
</div>

<div class="row mt-2">
    <div class="mealTitle">These are the ingredients that are preferred by the member</div>
</div>


<div class="row mt-4">
    <div class="mealTitle">Liked Ingredients</div>
</div>

<div class="row mt-3">
    
        <div class="col-2 ml-1 activeCol2"  *ngFor='let Ingredient of allPrefer.likedIngredients;let i=index;'>
            <div class="curisieSubTitle">{{Ingredient}}</div>
            <div class="crossMain">
            <div class="cross" (click)="removePreference(i,5)">X</div>
            </div>
        </div>

       


        <div class="col-2">
            <div class="cross1Main" (click)="addPreference(5)">
                <div class="cross2Sub">
                <div class="cross1">+</div>
                </div>
                </div>
        </div>

        <div class="col-12 flex mt-4" *ngIf="isAddValue == 5">
            <input type="text" class="form-control textField textContent" style="width:30%"   [(ngModel)]="preferValue" [ngModelOptions]="{standalone: true}" placeholder="Enter Value"/> &nbsp;&nbsp; <button type="button" (click)="savePreference()" class="btn btnClass">Save</button> 
        </div>
       
    </div>


    <div class="row mt-4">
        <div class="mealTitle">Disliked Ingredients</div>
    </div>
    <div class="row mt-3">
        
        <div class="col-2 ml-1 activeCol2" *ngFor='let dislike of allPrefer.dislikedIngredients;let i =index'>
            <div class="curisieSubTitle">{{dislike}}</div>
            <div class="crossMain">
            <div class="cross" (click)="removePreference(i,6)">X</div>
            </div>
        </div>

      

        <div class="col-2">
            <div class="cross1Main" (click)="addPreference(6)">
                <div class="cross2Sub">
                <div class="cross1">+</div>
                </div>
                </div>
        </div>
        
        <div class="col-12 flex mt-4" *ngIf="isAddValue == 6">
            <input type="text" class="form-control textField textContent" style="width:30%"  [(ngModel)]="preferValue" [ngModelOptions]="{standalone: true}" placeholder="Enter Value"/> &nbsp;&nbsp; <button type="button" (click)="savePreference()" class="btn btnClass">Save</button> 
        </div>

    </div>

