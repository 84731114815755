import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { Input } from '@angular/core';
@Component({
  selector: 'app-meal-member-basic-details',
  standalone: true,
  imports: [NgIf],
  templateUrl: './meal-member-basic-details.component.html',
  styleUrl: './meal-member-basic-details.component.css'
})
export class MealMemberBasicDetailsComponent {
@Input() memberDetail:any;
}
