import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { NgFor } from '@angular/common';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-preference',
  standalone: true,
  imports: [NgFor,NgIf,FormsModule,NgClass],
  templateUrl: './preference.component.html',
  styleUrl: './preference.component.css'
})
export class PreferenceComponent {
  @Input() allPrefer:any;
  @Input() selectedType:any;
  isSingle:boolean=false;
  isAddValue:any=0;
  preferValue:any='';
  isKidFriendly:any=1;
  isPlanFriendly:any=1;
  
  constructor(private toast:ToastService) {
      
  }

  savePreference(){
    let checkDuplicate = 1;
    var regex = new RegExp("^[a-zA-Z ]+$");
    //var str = String.fromCharCode(this.preferValue);
    if (regex.test(this.preferValue)) {
      
 

    if(this.preferValue == '' ||  this.preferValue == null){
      this.toast.errorMessage('Please enter the value.');
    }else{
      this.preferValue = this.preferValue.trim();
      this.preferValue = this.preferValue.toUpperCase();
    console.log(this.allPrefer.cuisine.likedCuisines);
   
     if(this.isAddValue == 1){

      if(this.allPrefer.cuisine.defaultCuisines.indexOf(this.preferValue) !== -1) {
        //console.timeLog('Name already exists!');
        checkDuplicate = 2;
      }else{
        //const words = this.preferValue;

        this.allPrefer.cuisine.defaultCuisines.push(this.preferValue);
      }
     
    }
    else if(this.isAddValue == 2){
      if(this.allPrefer.cuisine.likedCuisines.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.cuisine.dislikedCuisines.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
       // const words = this.preferValue;
        this.allPrefer.cuisine.likedCuisines.push(this.preferValue);
      }
      
    }
    else if(this.isAddValue == 3){
      if(this.allPrefer.cuisine.dislikedCuisines.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.cuisine.likedCuisines.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
       
        //this.allPrefer.cuisine.dislikedCuisines.push(words[0].toUpperCase() + words.substring(1));
        this.allPrefer.cuisine.dislikedCuisines.push(this.preferValue);
      }
     // this.allPrefer.cuisine.dislikedCuisines.push(this.preferValue);
    }
    else if(this.isAddValue == 4){
      if(this.allPrefer.cuisine.foodRestrictions.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }else{
       
        this.allPrefer.cuisine.foodRestrictions.push(this.preferValue);
      }
     // this.allPrefer.cuisine.foodRestrictions.push(this.preferValue);
    }
    else if(this.isAddValue == 5){
      if(this.allPrefer.likedIngredients.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.dislikedIngredients.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
        const words = this.preferValue;
        this.allPrefer.likedIngredients.push(this.preferValue.toUpperCase());
      }
      //this.allPrefer.likedIngredients.push(this.preferValue);
    }
    else if(this.isAddValue == 6){
      if(this.allPrefer.dislikedIngredients.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.likedIngredients.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
        const words = this.preferValue;
        this.allPrefer.dislikedIngredients.push(this.preferValue.toUpperCase());
      }
      //this.allPrefer.dislikedIngredients.push(this.preferValue);
    }

    if(checkDuplicate >  1){
      this.toast.errorMessage('Name is already exit.');
      checkDuplicate = 1;
    }else{
      this.toast.successMessage('Preference is added successfully.');
    }
    this.preferValue = '';
    this.isAddValue = 0;
  }
}else{
this.toast.errorMessage('Enter only characters.')
//  this.preferValue.preventDefault();
  return false;
  }
  }

  removePreference(index:any,type:any){
    if(type == 1){     
      this.allPrefer.cuisine.defaultCuisines.splice(index,1);
    }
    else if(type == 2){     
      this.allPrefer.cuisine.likedCuisines.splice(index,1);
    }
    else if(type == 3){     
      this.allPrefer.cuisine.dislikedCuisines.splice(index,1);
    }
    else if(type == 4){     
      this.allPrefer.cuisine.foodRestrictions.splice(index,1);
    }
    else if(type == 5){     
      this.allPrefer.likedIngredients.splice(index,1);
    }
    else if(type == 6){     
      this.allPrefer.dislikedIngredients.splice(index,1);
    }
    this.toast.successMessage('Preference is removed successfully.');
  }

  kidFriendly(type:any){
    
      if(type == 1){
        this.allPrefer.lifeStyle.isKidFriendly = false;
        this.isKidFriendly =2;
      }else{
        this.allPrefer.lifeStyle.isKidFriendly = true;
        this.isKidFriendly =1;
      }
  }

  planFriendly(type:any){
    
    if(type == 1){
      this.allPrefer.lifeStyle.isMealPlanningFriendly = false;
      this.isPlanFriendly =2;
    }else{
      this.allPrefer.lifeStyle.isMealPlanningFriendly = true;
      this.isPlanFriendly =1;
    }
}



addPreference(type:any){
  this.isAddValue = type;
}


}
