<div class="mainTitle">Meal Planner</div>  

<app-meal-notification [expiringCount]="countRes.expiring" [unassignedCount]="countRes.unassigned"></app-meal-notification>

<div class="mainTitle mt-5">Meal Plan</div> 

<div class="col-md-12 mt-4 flex" style="padding: 0;">
    <div class="col-md-6">
    <mat-list role="list" style="display: flex;">
        <mat-list-item class="col-md-3"  role="listitem" >
            <div [ngClass]="tab1Css" (click)="changeTab('tab1')">
                <div class="tabContent">All Members</div>
            </div>
        </mat-list-item>
        <mat-list-item class="col-md-4"  role="listitem">
            <div [ngClass]="tab2Css" (click)="changeTab('tab2')" >
                <div class="tabContent">Without Meal Plan</div>
            </div>
        </mat-list-item>
        <mat-list-item class="col-md-5" role="listitem" >
            <div [ngClass]="tab3Css" (click)="changeTab('tab3')">
                <div class="tabContent">Meal Plan expiring in 3 days</div>
            </div>
            </mat-list-item>
        
      </mat-list>
    </div>

    <div class="col-md-3 textAlign mt-2">
         <input matInput type="textbox" placeholder="Search by member" class="form-control textField" [(ngModel)]="name" (keyup)="searchByMember($event)" />
    </div>
</div>

<ng-container *ngFor="let rec of memberList;">
<div class="row mt-5 mr-3">
    <div class="col-sm-4" *ngFor="let r of rec;">
        <app-member-meal-details [memberMealDetails]="r" [selectedTab]="selectedTab"></app-member-meal-details>
    </div>
  </div>
</ng-container>
<div class="row mt-4" *ngIf="this.resp['totalCount']==0">
        No Record Found..
</div>
<div class="row mt-3 justify-content-center">
<mat-paginator #paginator
    (page)="pageChangeEvent($event)"
    [pageSize]="10"
    [pageIndex]="0"
    [length]="this.resp['totalCount']"
    [pageSizeOptions]="[10, 20, 50]"
    showFirstLastButton>
  </mat-paginator>
   </div>
