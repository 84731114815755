import { Component } from '@angular/core';
import { BasicDetailsComponent } from '../basic-details/basic-details.component';
import {MatListModule} from '@angular/material/list';
import { Input } from '@angular/core';
import { NgFor } from '@angular/common';
import { NgIf } from '@angular/common';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-meal-details',
  standalone: true,
  imports: [BasicDetailsComponent,MatListModule,NgFor,NgIf,NgClass],
  templateUrl: './meal-details.component.html',
  styleUrl: './meal-details.component.css'
})
export class MealDetailsComponent {
  allData:any=[];
  @Input() details:any;
  isIngredient:boolean =true;
  isCooking:boolean =false;
  isType:any=1;
  ngOnInit(): void {
  this.allData = localStorage.getItem('viewRecipe');
  this.allData = JSON.parse(this.allData);
  console.log(this.allData);
  }

  tabShow(type:any){
    if(type == 1){
      this.isIngredient = true;
      this.isCooking =false;
      this.isType = 1;
    }else{
      this.isIngredient = false;
      this.isCooking =true;
      this.isType = 2;
    }
      
  }
}
